import { useEffect, useRef, useState } from "react";
import { FormatDate, FormatSimpleDate } from "../../../utilities/FormatDate";

export const FeedingSessionDropdown = ({
    options,
    feedingTimestampsForComparison,
    setFeedingTimestampsForComparison
}) => {

    const [searchTerm, setSearchTerm] = useState("");
    // const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);


    const filteredOptions = options.filter(option =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleCheckboxChange = (option) => {
        setFeedingTimestampsForComparison(prevState =>
            prevState.includes(option)
                ? prevState.filter(item => item !== option)
                : [...prevState, option]
        );
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false); // Close the dropdown
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="dropdown-container" ref={dropdownRef}>
            <div className="dropdown-header align-items-center" onClick={toggleDropdown}>
                {feedingTimestampsForComparison.length > 0
                    ? `Comparing ${feedingTimestampsForComparison.length + 1} feeding sessions`
                    : <div className="d-flex  gap-2 me-3">
                        <p className="fw-semibold text-color mb-0">Compare Sessions</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path d="M6.33335 17.5L2.16669 13.3333L6.33335 9.16667L7.52085 10.3333L5.35419 12.5H18V14.1667H5.35419L7.52085 16.3333L6.33335 17.5ZM14.6667 10.8333L13.4792 9.66667L15.6459 7.5H3.00002V5.83333H15.6459L13.4792 3.66667L14.6667 2.5L18.8334 6.66667L14.6667 10.8333Z" fill="#5F2E86" />
                        </svg>
                    </div>}
            </div>

            {isOpen && (
                <div className="dropdown-menu">
                    <input
                        type="text"
                        className="dropdown-search"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    <ul className="dropdown-list">
                        {filteredOptions.map(option => (
                            <li key={option} className="dropdown-list-item">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={feedingTimestampsForComparison.includes(option)}
                                        onChange={() => handleCheckboxChange(option)}
                                    />
                                    {FormatDate(option) }
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
