import React, { useContext, useEffect, useState } from 'react'
import { CollapsibleCard } from '../Collapsible/CollapsibleCard'
import { FlagsSection } from './PatientFullDetailsCardComponents/FlagsSection'
import { FeedingChart } from '../Charts/FeedingChart'
import { useGetFlags } from '../CustomHooks/useGetFlags'
import { StarRating } from './PatientFullDetailsCardComponents/StarRating'
import { BiomarkersSection } from './PatientFullDetailsCardComponents/BiomarkersSection'
import FormatDateWithMonth from '../../utilities/FormatDate'
import { PatientHeader } from './PatientFullDetailsCardComponents/PatientHeader'
import { PatientContext } from '../../data_layer/context/Patient/PatientContext'
import { useGetFeedingDetails } from '../CustomHooks/useGetFeedingDetails'
import { useGetBiomarkersListByPatientId } from '../CustomHooks/useGetBiomarkersListByPatientId'

export const PatientFullDetailsCard = ({
    index,
    feedingTimestamp,
    isComparingFeedings,
    isComparingPatients
}) => {

    const {patients} = useContext(PatientContext);

    const {patient} = patients[index];

    const timestamp = feedingTimestamp || patients[index].currentFeedingTimestamp

    const {feeding} = useGetFeedingDetails(timestamp);

    const [granularity, setGranularity] = useState('full');

    const { isLoading: loadingFlags, flags } = useGetFlags(timestamp);

    const { biomarkers, getBiomarkers } = useGetBiomarkersListByPatientId(patients[index]?.id);

    const changeGranularity = (event) => {
        setGranularity(event.target.value);
    }

    useEffect(() => {
      getBiomarkers();
    }, []);
    

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 mb-4">

                    <div className="w-100 rounded-4 bg-white px-0 pb-3">
                        {/* <!-- Header --> */}
                        {
                            isComparingPatients &&
                            <PatientHeader
                                index={index}
                                isComparingPatients={isComparingPatients}/>
                        }

                        <div className="row border-top">



                            <div className="col-lg-4 pe-0 py-3">
                                {/* <!-- Content --> */}
                                <div className="d-flex">
                                    <div className="px-3 w-100 d-flex align-items-stretch gap-4">

                                        <div className="d-flex flex-column patient-card-feeding-details-width">
                                            <p className="m-0">Feeding details</p>

                                            <div className="row mt-3">
                                                <div className="col-6 py-1 mb-3">
                                                    <p className="text-sm fw-semibold mb-0">Weight at feed</p>
                                                    <p className="mb-0 lh-sm fw-semibold text-md">{feeding?.weight != 0 ? `${feeding?.weight} ${feeding?.weight_uom}` : '-'}</p>
                                                </div>
                                                <div className="col-6 py-1 mb-3">
                                                    <p className="text-sm fw-semibold mb-0">Vol. consumed</p>
                                                    <p className="mb-0 lh-sm fw-semibold text-md">{feeding?.volume != 0 ? `${feeding?.volume} ${feeding?.volume_uom}` : '-'}</p>
                                                </div>
                                                <div className="col-6 py-1 mb-3">
                                                    <p className="text-sm fw-semibold mb-0">Nipple</p>
                                                    <p className="mb-0 lh-sm fw-semibold text-md">{feeding?.nipple ? feeding?.nipple : '-'}</p>
                                                </div>
                                                <div className="col-6 py-1 mb-3">
                                                    <p className="text-sm fw-semibold mb-0">Nutrient</p>
                                                    <p className="mb-0 lh-sm fw-semibold text-md">{feeding?.nutrient_type ? feeding?.nutrient_type : '-'}</p>
                                                </div>
                                                <div className="col-6 py-1">
                                                    <p className="text-sm fw-semibold mb-0">Position</p>
                                                    <p className="mb-0 lh-sm fw-semibold text-md">{feeding?.position ? feeding?.position : '-'}</p>
                                                </div>
                                                <div className="col-6 py-1">
                                                    <p className="text-sm fw-semibold mb-0">Paced</p>
                                                    <p className="mb-0 lh-sm fw-semibold text-md">{!feeding?.paced || feeding?.paced === 0 ? 'No' : 'Yes'}</p>
                                                </div>
                                                <div className="col-12 py-1 mt-4">
                                                    <p className="text-sm lh-1 fw-semibold mb-0 text-muted">Comment</p>
                                                    <p className="mb-0 lh-sm fw-normal">{feeding?.note}</p>
                                                </div>

                                                <StarRating title="Readiness to feed" rating={feeding?.readiness_score} />
                                                <StarRating title="Quality of feed" rating={feeding?.quality_score} />
                                            </div>
                                        </div>
                                        <BiomarkersSection biomarkers={biomarkers?.feedings?.find((feed) => feed.timestamp === feeding?.timestamp)?.biomarkers} />
                                        <FlagsSection flags={flags} />

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 py-3 px-4">
                                {/* <!-- Content --> */}
                                <div className="d-flex ps-4 w-100">
                                    <div className="px-3 w-100 d-flex align-items-stretch gap-4">
                                        <div className="d-flex flex-column w-100">
                                            <div className="d-flex align-items-center w-100">
                                                <div>
                                                    <p className="m-0">{FormatDateWithMonth(feeding?.timestamp)}</p>
                                                </div>
                                                <div className="mx-auto d-flex align-items-center gap-3">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M6.83333 11.5L12.7083 5.625L11.5417 4.45833L6.83333 9.16667L4.45833 6.79167L3.29167 7.95833L6.83333 11.5ZM0.5 15.5V0.5H15.5V15.5H0.5Z" fill="#87c174" />
                                                        </svg>
                                                        <p className="mb-0 text-base text-green">Suck & Swallow/sec</p>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M6.83333 11.5L12.7083 5.625L11.5417 4.45833L6.83333 9.16667L4.45833 6.79167L3.29167 7.95833L6.83333 11.5ZM0.5 15.5V0.5H15.5V15.5H0.5Z" fill="#396ec6" />
                                                        </svg>
                                                        <p className="mb-0 text-base text-blue">Breathing/sec</p>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M6.83333 11.5L12.7083 5.625L11.5417 4.45833L6.83333 9.16667L4.45833 6.79167L3.29167 7.95833L6.83333 11.5ZM0.5 15.5V0.5H15.5V15.5H0.5Z" fill="#45367f" />
                                                        </svg>
                                                        <p className="mb-0 text-base text-lilac">Flagged events</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <select name="neo_date" id="" className="neo_input_select" onChange={changeGranularity}>

                                                        <option value='full'>Full</option>
                                                        <option value='5m'>5 min</option>
                                                        <option value='1m'>1 min</option>
                                                        <option value='30s'>30 sec</option>
                                                        <option value='10s'>10 sec</option>

                                                    </select>
                                                    {/* <button className="bg-transparent border-0 p-0 h-fit lh-1">
                                                        <i className='bx bx-plus ms-1' ></i>
                                                    </button> */}
                                                </div>
                                            </div>
                                            <div className="w-full mt-4 overflow-x-scroll">
                                                <FeedingChart feeding={feeding} flags={flags} isLightGraph={false} granularity={granularity} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
